import { useEffect, useMemo, useRef, useState } from "react";
import { Icon } from "@blueprintjs/core";
import { ItemRendererProps } from "@blueprintjs/select/src/common/itemRenderer";
import { GridApi } from "ag-grid-community";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import useHiddenCellElements from "@components/Modeling/ModelingFrame/Table/hooks";
import EditStatusOption from "@components/Modeling/StatusesTable/Cells/MenuItemOption/EditStatusOption";
import MultiSelectTag, { MultiSelectTagPropsItem } from "@components/MultiSelectTag";
import { Popover } from "@components/Popover";
import { Tag } from "@components/Tag";
import appStore from "@store/AppStore";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";
import { IStatusOption } from "@store/StatusOptionStore";
import { getRandomHexColor } from "@utilities";
import { closePopover } from "@utilities/DomUtils";

import styles from "./StatusInstanceSelectCell.module.scss";

type StatusInstanceSelectCellProps = {
  statusDefinition: IStatusDefinition;
  statusInstance?: IStatusInstance;
  gridApi?: GridApi;
  disabled?: boolean;
  containerClassName?: string;
  // portalContainer is the container used to render the popover content.
  // If not provided, it will be rendered in the body. This can be useful if
  // the popover must be contained inside a certain element
  portalContainer?: HTMLElement;
  onAddStatusInstance(statusDefinition: IStatusDefinition, value: string): Promise<IStatusInstance | undefined>;
  onDeleteStatusInstance(statusInstance: IStatusInstance): void;
};

const optionMapper = (option: IStatusOption): MultiSelectTagPropsItem => ({
  id: option.id,
  label: option.label,
  color: option.color,
});

const StatusInstanceSelectCell = (props: StatusInstanceSelectCellProps) => {
  const [color, setColor] = useState("");
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { statusDefinition, statusInstance, disabled, containerClassName, portalContainer, onAddStatusInstance, gridApi } = props;
  const isSingleSelect = statusDefinition?.type === StatusType.singleSelect;
  const items = statusDefinition.statusOptions.map(optionMapper);
  const selectedItems = useMemo(() => statusInstance?.multiSelectValues.map(optionMapper) || [], [statusInstance?.multiSelectValues]);
  const selectedIds = selectedItems.map(i => i.id);
  const itemsContainerRef = useRef<HTMLDivElement>(null);
  const itemsResultRef = useRef<HTMLDivElement>(null);
  const { hiddenElements, singleItemVisibleLeft } = useHiddenCellElements({
    items: selectedItems,
    itemsContainerRef,
    itemsResultRef,
  });

  useEffect(() => {
    setColor(getRandomHexColor(statusDefinition?.statusOptions?.map(d => d.color)));
  }, [statusDefinition?.statusOptions, statusDefinition?.statusOptions.length]);

  const handleItemSelect = async (item: MultiSelectTagPropsItem) => {
    // if item is not exist in status options, create new status option
    if (item.id === item.label) {
      handleCreateNew(item.label);
      return;
    }

    if (!statusInstance) {
      onAddStatusInstance(statusDefinition, JSON.stringify([item.id]));
    } else {
      if (selectedIds.includes(item.id)) {
        handleItemRemove(item);
      } else {
        statusInstance.setValue(JSON.stringify(isSingleSelect ? [item.id] : [...selectedIds, item.id]));
      }
    }
  };

  const handleItemRemove = (item: MultiSelectTagPropsItem) => {
    statusInstance?.setValue(JSON.stringify(selectedIds.filter(i => i !== item.id)));
  };

  const renderItem = (item: MultiSelectTagPropsItem, skipHiding?: boolean) => {
    if (!skipHiding && hiddenElements.includes(item.id)) {
      return null;
    }

    return (
      <Tag
        className={classNames({ [styles.statusInstanceSelectCellSelectedItemsContainerSingleTag]: !skipHiding && singleItemVisibleLeft })}
        style={{ backgroundColor: item.color }}
        key={item.id}
      >
        {item.label}
      </Tag>
    );
  };

  const renderTarget = () => (
    <div
      ref={itemsContainerRef}
      className={classNames(styles.statusInstanceSelectCellSelectedItemsContainer, containerClassName, { [styles.disabled]: disabled })}
    >
      <div className={styles.statusInstanceSelectCellItemsResult}>
        {selectedItems.map(i => renderItem(i))}
        {!!hiddenElements.length && <Tag>...</Tag>}
      </div>
      {/* we always render second hidden row to calculate width of the whole row correctly */}
      <div
        ref={itemsResultRef}
        className={classNames(styles.statusInstanceSelectCellItemsResult, styles.statusInstanceSelectCellItemsResultAll)}
      >
        {selectedItems.map(i => renderItem(i, true))}
      </div>
    </div>
  );

  const itemRenderer = (item: MultiSelectTagPropsItem, itemProps?: ItemRendererProps) => {
    const statusOption: IStatusOption | undefined = statusDefinition.statusOptions.find(o => o.id === item.id);
    return (
      <div
        className={classNames(styles.statusInstanceSelectCellListItem, {
          [styles.statusInstanceSelectCellListItemActive]: selectedIds.includes(item.id),
        })}
      >
        <div className={styles.statusInstanceSelectCellTagHolder} onMouseDown={itemProps?.handleClick}>
          {renderItem(item, true)}
        </div>
        {statusOption && (
          <Popover
            className={styles.statusInstanceSelectCellListItemMore}
            content={<EditStatusOption statusOption={statusOption} />}
            position="bottom-right"
          >
            <Button small minimal icon="more" e2eIdentifiers={["menu-item-option", "more"]} />
          </Popover>
        )}
      </div>
    );
  };

  const handleCreateNew = (label: string) => {
    const statusOption = appStore.workspaceModel?.addNewStatusOption(statusDefinition, label, color);

    if (statusOption) {
      handleItemSelect(optionMapper(statusOption));
      setColor(getRandomHexColor(statusDefinition?.statusOptions?.map(d => d.color)));
    }
  };

  const createNewItemRenderer = (label: string) => {
    const lowercaseLabel = label.toLowerCase();
    if (statusDefinition?.statusOptions.some(o => o.label.toLowerCase() === lowercaseLabel)) {
      return;
    }

    return (
      <div
        onMouseDown={() => handleCreateNew(label)}
        className={classNames(styles.statusInstanceSelectCellListItem, styles.statusInstanceSelectCellAddNew)}
      >
        <Icon icon="add" />
        Create
        {renderItem({ id: "", label, color }, true)}
      </div>
    );
  };

  const handleInteraction = (nextOpenState: boolean) => {
    setIsPopoverOpen(nextOpenState);
    if (nextOpenState) {
      gridApi?.setGridOption("onBodyScroll", () => {
        closePopover("#root");
      });
    } else {
      gridApi?.setGridOption("onBodyScroll", undefined);
    }
  };

  return (
    <div className={styles.statusInstanceSelectCell} onClick={() => setIsPopoverOpen(true)}>
      <MultiSelectTag
        compact
        isPopoverOpen={isPopoverOpen}
        onInteraction={gridApi ? handleInteraction : undefined}
        createNewItemRenderer={createNewItemRenderer}
        customItemRenderer={itemRenderer}
        customTarget={renderTarget}
        items={items}
        selectedItems={selectedItems}
        onItemSelect={handleItemSelect}
        onItemRemove={handleItemRemove}
        disabled={disabled}
        portalContainer={portalContainer}
      />
    </div>
  );
};

export default observer(StatusInstanceSelectCell);
