import { AxiosResponse } from "axios";
import groupBy from "lodash/groupBy";
import { cast, flow, Instance, SnapshotIn, types } from "mobx-state-tree";

import { showApiErrorToast } from "@components/UiLayers/toaster";
import { RollupEvent, RollupEventsCountCheckRequestDto, RollupEventsFindAllDto } from "@rollup-api/models/rollupEvents";
import { RollupEventsFindAllRto } from "@rollup-api/models/rollupEvents/rollupEventFindAllRto.model";
import { RollupEventSetStatusRequestDto } from "@rollup-api/models/rollupEvents/RollupEventSetStatusRequestDto.model";
import { CommentDisplayParentType } from "@store/CommentStore";
import { CommentThreadListStore } from "@store/CommentThreadListStore";
import { StoreType } from "@store/types";
import { rollupClient } from "src/core/api";

export enum RollupEventStatuses {
  read = "read",
  unread = "unread",
  archived = "archived",
  unarchived = "unarchived",
}

export enum RollupEventLocations {
  comment = "comment",
  block = "block",
  report = "report",
}

export enum RollupEventLoadStatus {
  notLoaded,
  loading,
  loaded,
  error,
}

export enum RollupEventActions {
  mentionedInComment = "mentionedInComment",
  commentCreated = "commentCreated",
  commentUpdated = "commentUpdated",
  repliedToComment = "repliedToComment",
  commentDeleted = "commentDeleted",
}

export type RollupEventStatus = keyof typeof RollupEventStatuses;
export type RollupEventLocation = keyof typeof RollupEventLocations;

const RollupEventItemMetadata = types.model("RollupEventMetaData", {
  subject: types.array(types.string),
  commentId: types.maybe(types.string),
  entityId: types.maybe(types.string),
  entityType: types.maybe(types.string),
  mentionEntityId: types.maybe(types.string),
  mentionEntityType: types.maybe(
    types.enumeration<RollupEventLocation>("RollupEntityType", [
      RollupEventLocations.comment,
      RollupEventLocations.block,
      RollupEventLocations.report,
    ])
  ),
  parentId: types.maybe(types.string),
  parentType: types.maybe(types.enumeration(Object.values(StoreType))),
  displayParentId: types.maybe(types.string),
  displayParentType: types.maybe(
    types.enumeration<CommentDisplayParentType>("CommentDisplayParentType", [...Object.values(CommentDisplayParentType)])
  ),
  mentionSubject: types.maybe(types.string),
  parentCommentId: types.maybe(types.string),
  workspaceLabel: types.optional(types.string, ""),
});

export const RollupEventItem = types
  .model("RollupEvent", {
    id: types.string,
    createdBy: types.maybe(types.string),
    createdAt: types.string,
    updatedAt: types.maybe(types.string),
    eventId: types.string,
    status: types.string,
    entityType: types.string,
    action: types.maybe(types.enumeration(Object.values(RollupEventActions))),
    isRead: types.boolean,
    isArchived: types.boolean,
    metadata: RollupEventItemMetadata,
    subscribers: types.optional(types.array(types.string), []),
    workspaceId: types.string,
    workspace: types.maybe(types.string),
    commentThreadList: types.optional(CommentThreadListStore, {}),
  })
  .actions(self => ({
    setSelfStatusInTheStoreOnly({ isRead, isArchived }: { isRead?: boolean; isArchived?: boolean }) {
      if (isRead !== undefined) {
        self.isRead = isRead;
      }
      if (isArchived !== undefined) {
        self.isArchived = isArchived;
      }
    },
  }));

export const RollupEventsStore = types
  .model("RollupEvents", {
    rollupEvents: types.maybe(types.array(RollupEventItem)),
    topRollupEventsTotal: types.maybe(types.array(RollupEventItem)),
    topRollupEventsMention: types.maybe(types.array(RollupEventItem)),
    topRollupEventsArchive: types.maybe(types.array(RollupEventItem)),
    unreadInboxRollupEventCount: types.maybe(types.number),
    readInboxRollupEventCount: types.maybe(types.number),
    unreadArchiveRollupEventCount: types.maybe(types.number),
    readArchiveRollupEventCount: types.maybe(types.number),
  })
  .volatile(() => ({
    fetchStatus: RollupEventLoadStatus.notLoaded,
  }))
  .actions(self => ({
    getRollupEventsCount: flow(function* getRollupEventsCount(
      dto: RollupEventsCountCheckRequestDto
    ): Generator<any, number | undefined, any> {
      try {
        const { data, status } = yield rollupClient.rollupEvents.getRollupEventsCount(dto);
        if (status !== 200 && status !== 204) {
          console.warn(`Error updating inbox`);
          showApiErrorToast("Error updating inbox", new Error());
          return;
        }
        return data.eventsNumber;
      } catch (error) {
        console.warn(error);
        return;
      }
    }),
    logInvalidEvents(invalidEvents: IRollupEventSnapshotIn[]) {
      if (invalidEvents.length > 0) {
        console.warn("Invalid rollup events", invalidEvents);
        try {
          self.rollupEvents = cast([...invalidEvents]);
        } catch (err) {
          console.warn("Error casting invalid rollup events", err);
        }
      }
    },
  }))
  .actions(self => ({
    getRollupEvents: flow(function* getRollupEvents(
      dto: RollupEventsFindAllDto
    ): Generator<any, RollupEvent[] | null, AxiosResponse<RollupEventsFindAllRto>> {
      try {
        self.fetchStatus = RollupEventLoadStatus.loading;
        const { data: rollupEvents, status } = yield rollupClient.rollupEvents.retrieveAll(dto);
        if (status !== 200 || !rollupEvents) {
          console.warn(`Error checking inbox`);
          showApiErrorToast("Error checking inbox", new Error());
          return null;
        }
        const { validEvents = [], invalidEvents = [] } = groupBy(rollupEvents.events, (e: RollupEvent) =>
          RollupEventItem.is(e) ? "validEvents" : "invalidEvents"
        );
        self.logInvalidEvents(invalidEvents);
        self.rollupEvents = cast([...validEvents]);
        self.fetchStatus = RollupEventLoadStatus.loaded;
        return rollupEvents.events;
      } catch (error) {
        console.warn(error);
        self.fetchStatus = RollupEventLoadStatus.error;
        return null;
      }
    }),
    deleteRollupEvent: flow(function* deleteRollupEvent(id: string): Generator<any, boolean, any> {
      try {
        const index = self.rollupEvents?.findIndex(e => e.eventId === id);
        const { status } = yield rollupClient.rollupEvents.deleteById(id);
        if (status !== 200 || index === undefined || index === -1) {
          console.warn(`Error deleting rollup event ${id}`);
          showApiErrorToast(`Error deleting rollup event ${id}`, new Error());
          return false;
        }
        // remove the event from the store
        self.rollupEvents?.splice(index, 1);
        return true;
      } catch (error) {
        console.warn(error);
        return false;
      }
    }),
    getTopRollupEventsTotal: flow(function* getTopRollupEventsTotal(): Generator<any, RollupEvent[] | null, any> {
      const rollupEventsFindAllDto: RollupEventsFindAllDto = {
        statuses: [RollupEventStatuses.read, RollupEventStatuses.unread],
        // TODO: implement infinite scroll instead
        take: 9999,
        skip: 0,
      };
      try {
        const { data: rollupEvents, status } = yield rollupClient.rollupEvents.retrieveAll(rollupEventsFindAllDto);
        if (status !== 200 || !rollupEvents) {
          console.warn(`Error checking inbox`);
          showApiErrorToast("Error checking inbox", new Error());
          return null;
        }
        self.topRollupEventsTotal = cast([...rollupEvents.events]);
        return rollupEvents;
      } catch (error) {
        console.warn(error);
        return null;
      }
    }),
    // TODO: Need an endpoint to check only mention notifications
    getTopRollupEventsMention: flow(function* getTopRollupEventsMention(): Generator<any, RollupEvent[] | null, any> {
      const rollupEventsFindAllDto: RollupEventsFindAllDto = {
        statuses: [RollupEventStatuses.read, RollupEventStatuses.unread],
        // TODO: implement infinite scroll instead
        take: 9999,
        skip: 0,
      };

      try {
        const { data: rollupEvents, status } = yield rollupClient.rollupEvents.retrieveAll(rollupEventsFindAllDto);
        if (status !== 200 || !rollupEvents) {
          console.warn(`Error checking inbox`);
          showApiErrorToast("Error checking inbox", new Error());
          return null;
        }
        self.topRollupEventsMention = cast([...rollupEvents.events]);
        return rollupEvents;
      } catch (error) {
        console.warn(error);
        return null;
      }
    }),
    getTopRollupEventsArchive: flow(function* getTopRollupEventsArchive(): Generator<any, RollupEvent[] | null, any> {
      const rollupEventsFindAllDto: RollupEventsFindAllDto = {
        statuses: [RollupEventStatuses.archived],
        // TODO: implement infinite scroll instead
        take: 9999,
        skip: 0,
      };

      try {
        const { data: rollupEvents, status } = yield rollupClient.rollupEvents.retrieveAll(rollupEventsFindAllDto);
        if (status !== 200 || !rollupEvents) {
          console.warn(`Error checking inbox`);
          showApiErrorToast("Error checking inbox", new Error());
          return null;
        }
        self.topRollupEventsArchive = cast([...rollupEvents.events]);
        return rollupEvents;
      } catch (error) {
        console.warn(error);
        return null;
      }
    }),
    setRollupEventStatus: flow(function* setRollupEventStatus(dto: RollupEventSetStatusRequestDto): Generator<any, boolean | null, any> {
      let isRead: boolean | undefined = undefined;
      let isArchived: boolean | undefined = undefined;
      switch (dto.toStatus) {
        case RollupEventStatuses.read:
          isRead = true;
          break;
        case RollupEventStatuses.unread:
          isRead = false;
          break;
        case RollupEventStatuses.archived:
          isArchived = true;
          break;
        case RollupEventStatuses.unarchived:
          isArchived = false;
          break;
        default:
          throw new Error(`Unknown status ${dto.toStatus}`);
      }
      // since changing status seemed slow, let's do optimistic update
      // first save previous values
      const eventsAndPreviousStatuses = self.rollupEvents?.map(e => ({ id: e.id, isRead: e.isRead, isArchived: e.isArchived }));
      // then update the status in the store
      if (self.rollupEvents) {
        // self.rollupEvents contains the events that can be displayed in the events list, regardless
        // of the workspace selected in the ws selector, and regardless of pagination or infinite scroll
        // status. Since we are making the below changes to provide a sense of speed to the user,
        // we only need to worry about what is/can be visible without worrying about if there are more
        // events for the ws selected which are in BE but not downloaded yet.
        for (const e of self.rollupEvents) {
          if (!dto.ids) {
            // no ids given, mark everything
            e.setSelfStatusInTheStoreOnly({ isRead, isArchived });
          } else if (dto.ids.includes(e.id)) {
            // id(s) are given, mark only the items with the given ids
            e.setSelfStatusInTheStoreOnly({ isRead, isArchived });
          }
        }
      }

      // now update the status in BE
      try {
        const { data: response, status: responseStatus } = yield rollupClient.rollupEvents.setRollupEventsStatus(dto);
        if ((responseStatus !== 200 && responseStatus !== 201) || !response) {
          console.warn(`Error marking item(s) as ${status}`);
          showApiErrorToast(`Error marking item(s) as ${status}`, new Error());
          // since the request failed, revert the optimistic update
          if (self.rollupEvents) {
            for (const e of self.rollupEvents) {
              const eventToUpdate = eventsAndPreviousStatuses?.find(ev => ev.id === e.id);
              if (eventToUpdate) {
                e.setSelfStatusInTheStoreOnly({ isRead: eventToUpdate.isRead, isArchived: eventToUpdate.isArchived });
              }
            }
          }
          return null;
        }
        return response;
      } catch (error) {
        console.warn(error);
        return null;
      }
    }),
  }))
  .actions(self => ({
    markAllAsRead(workspaceId?: string) {
      const dto: RollupEventSetStatusRequestDto = {
        workspaceIds: workspaceId ? [workspaceId] : undefined,
        toStatus: RollupEventStatuses.read,
      };
      self.setRollupEventStatus(dto);
    },
    archiveAll(workspaceId?: string) {
      const dto: RollupEventSetStatusRequestDto = {
        workspaceIds: workspaceId ? [workspaceId] : undefined,
        toStatus: RollupEventStatuses.archived,
      };
      self.setRollupEventStatus(dto);
    },
    archiveAllRead(workspaceId?: string) {
      const dto: RollupEventSetStatusRequestDto = {
        workspaceIds: workspaceId ? [workspaceId] : undefined,
        fromStatuses: [RollupEventStatuses.read],
        toStatus: RollupEventStatuses.archived,
      };
      self.setRollupEventStatus(dto);
    },
  }))
  .views(self => ({
    eventWithId(id: string | undefined): IRollupEvent | undefined {
      if (id) {
        return self.rollupEvents?.find(e => e.id === id);
      }
      return undefined;
    },
  }));

export interface IRollupEvents extends Instance<typeof RollupEventsStore> {}
export interface IRollupEvent extends Instance<typeof RollupEventItem> {}
export interface IRollupEventSnapshotIn extends SnapshotIn<typeof RollupEventItem> {}
