import { MouseEvent } from "react";
import { Menu, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { RequirementValidationOperation } from "@rollup-api/models";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";

interface IValidationFormulaMenuProps {
  requirementBlock: IRequirementBlock;
  onClick(e: MouseEvent): void;
}

const ValidationFormulaMenu = (props: IValidationFormulaMenuProps) => {
  const { requirementBlock, onClick } = props;

  const handleClick = (value: RequirementValidationOperation) => {
    requirementBlock.setValidationOperation(value);
  };

  return (
    <Menu onClick={onClick}>
      <MenuDivider title="Arithmetic" />
      <MenuItem
        icon="equals"
        text="Equal-to"
        onClick={() => handleClick(RequirementValidationOperation.Equals)}
        e2eIdentifiers="equal-to"
      />
      <MenuItem
        icon="not-equal-to"
        text="Not-equal-to"
        onClick={() => handleClick(RequirementValidationOperation.NotEqualTo)}
        e2eIdentifiers="not-equal-to"
      />
      <MenuItem
        icon="greater-than"
        text="Greater-than"
        onClick={() => handleClick(RequirementValidationOperation.GreaterThan)}
        e2eIdentifiers="greater-than"
      />
      <MenuItem
        icon="greater-than-or-equal-to"
        text="Greater-than or equal-to"
        onClick={() => handleClick(RequirementValidationOperation.GreaterThanOrEqualTo)}
        e2eIdentifiers="greater-than-or-equal-to"
      />
      <MenuItem
        icon="less-than"
        text="Less-than"
        onClick={() => handleClick(RequirementValidationOperation.LessThan)}
        e2eIdentifiers="less-than"
      />
      <MenuItem
        icon="less-than-or-equal-to"
        text="Less-than or equal-to"
        onClick={() => handleClick(RequirementValidationOperation.LessThanOrEqualTo)}
        e2eIdentifiers="less-than-or-equal-to"
      />
    </Menu>
  );
};

export default observer(ValidationFormulaMenu);
