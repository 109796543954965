export function adjustTableToHaveHeader(htmlString: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(htmlString, "text/html");
  const table = doc.querySelector("table");

  if (!table) {
    return htmlString;
  }

  const thead = table.querySelector("thead");
  if (!thead) {
    const tbody = table.querySelector("tbody");
    const firstRow = tbody?.querySelector("tr");

    if (!firstRow || !tbody) {
      return htmlString;
    }

    const newThead = doc.createElement("thead");
    newThead.appendChild(firstRow.cloneNode(true));
    table.insertBefore(newThead, tbody);
    tbody.removeChild(firstRow);
  }

  return table.outerHTML;
}
