import { useState } from "react";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { applyActiveColumnStateConfig, getStringActualColumnsConfig } from "@components/Modeling/ModelingFrame/Table/TableComponent/utils";
import { defaultTableViewConfigTabId } from "@components/Modeling/ModelingFrame/Table/TableConfigTabs/constants";
import appStore from "@store/AppStore";

import ColumnTogglePanel, { TStateColumn } from "../ColumnTogglePanel";

import "./RightSidePanel.scss";

const RightSidePanel = () => {
  const [showToolPanel, setShowToolPanel] = useState(false);
  const [columnsState, setColumnsState] = useState<TStateColumn[]>([]);
  const { tableViewGridApi, activeTableViewConfigId } = appStore.env;
  const activeTableViewConfig = appStore.workspaceModel?.tableViewConfigs.get(activeTableViewConfigId);

  const handleCancel = () => {
    applyActiveColumnStateConfig(tableViewGridApi);
    setShowToolPanel(false);
  };

  const handelSave = () => {
    const actualConfig = getStringActualColumnsConfig(columnsState);

    if (!activeTableViewConfig || activeTableViewConfigId === defaultTableViewConfigTabId) {
      appStore.workspaceModel?.addNewTableViewConfig(actualConfig);
    } else {
      activeTableViewConfig.setConfig(actualConfig);
    }

    setShowToolPanel(false);
  };

  const handleToggleShowToolPanel = () => {
    if (showToolPanel) {
      handelSave();
      setShowToolPanel(false);
    } else {
      setShowToolPanel(true);
    }
  };

  return (
    <div className="right-side-panel">
      {showToolPanel && tableViewGridApi && (
        <ColumnTogglePanel
          api={tableViewGridApi}
          columnsState={columnsState}
          onCancel={handleCancel}
          onSave={handelSave}
          onColumnsStateChange={setColumnsState}
        />
      )}
      <Button
        className="right-side-panel--edit-columns"
        icon="list-columns"
        onClick={handleToggleShowToolPanel}
        e2eIdentifiers="open-tool-panel"
        active={showToolPanel}
        minimal
      >
        Edit columns
      </Button>
    </div>
  );
};

export default observer(RightSidePanel);
