import { useRef } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import { Button } from "@components/Button";
import { IPopoverRefType, Popover } from "@components/Popover";
import appStore from "@store/AppStore";
import { IReport } from "@store/ReportsStore";
import { getAssetLink } from "@utilities/LinkUtils";

import AttachmentPopup from "../../AttachmentPopup";

import styles from "./ReportCover.module.scss";

type Props = {
  report: IReport;
};

const ReportCover = (props: Props) => {
  const popoverRef = useRef<IPopoverRefType>(null);
  const popoverChRef = useRef<IPopoverRefType>(null);
  const workspaceId = appStore.workspaceModel?.id;
  const { report } = props;
  const { coverUrl } = report;

  const handleAddImage = (url: string) => {
    report.update({ coverUrl: url });
    popoverRef.current?.setState({ isOpen: false });
  };

  const className = classNames({ [styles.reportCover]: !!coverUrl });

  return coverUrl ? (
    <div
      data-testid="report-cover-image"
      className={styles.reportCoverImage}
      style={{ backgroundImage: coverUrl ? `url("${getAssetLink(coverUrl)}")` : undefined }}
    >
      <div>
        <Popover
          ref={popoverChRef}
          position="bottom-right"
          className={className}
          content={<AttachmentPopup workspaceId={workspaceId} fileUrl={coverUrl} onLinkChange={handleAddImage} />}
        >
          <Button className={styles.reportCoverButton} e2eIdentifiers="change-cover">
            Change cover
          </Button>
        </Popover>
      </div>
    </div>
  ) : (
    <div>
      <Popover
        ref={popoverRef}
        position="bottom-left"
        className={className}
        content={<AttachmentPopup workspaceId={workspaceId} fileUrl={coverUrl} onLinkChange={handleAddImage} />}
      >
        <Button minimal icon="media" text="Add cover image" className="report-page--button" e2eIdentifiers="add-cover-image" />
      </Popover>
    </div>
  );
};

export default observer(ReportCover);
