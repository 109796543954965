export enum ExecutionEnvironmentType {
  Python = "python3",
  Node = "node",
  Bun = "bun",
  Matlab = "matlab",
  Bash = "bash",
  Julia = "julia",
}

export enum ExecutionEnvironmentStatus {
  Pending = "pending",
  Creating = "creating",
  Ready = "ready",
  Error = "error",
}

export enum ExecutionStatus {
  Pending = "pending",
  Preparing = "preparing",
  Running = "running",
  Success = "success",
  Failure = "failure",
  Warning = "warning",
  Timeout = "timeout",
}

export function isExecutionStatusFinal(status: ExecutionStatus) {
  switch (status) {
    case ExecutionStatus.Success:
    case ExecutionStatus.Failure:
    case ExecutionStatus.Warning:
    case ExecutionStatus.Timeout:
      return true;
    default:
      return false;
  }
}
