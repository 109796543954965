import { RefObject, useEffect, useRef, useState } from "react";
import { InputGroup, Intent, Menu, MenuDivider } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { MenuItem } from "@components/MenuItem";
import { MenuItemDelete } from "@components/MenuItems";
import { IPopoverRefType } from "@components/Popover";
import appStore from "@store/AppStore";
import { IBlock } from "@store/BlockStore";
import { IStatusDefinition, StatusType } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";

import { CopyLinkMenuItem } from "../SmallComponents";

import { STATUS_MENU_ITEMS } from "./constants";

import "./StatusInstanceNavigation.scss";

type TStatusInstanceNavigationProps = {
  statusInstance?: IStatusInstance;
  statusDefinition: IStatusDefinition;
  block: IBlock;
  popoverRef: RefObject<IPopoverRefType>;
};

const StatusInstanceNavigation = (props: TStatusInstanceNavigationProps) => {
  const { statusInstance, statusDefinition, block, popoverRef } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(statusDefinition.label);

  useEffect(() => setValue(statusDefinition.label), [statusDefinition.label]);

  const handleClear = () => {
    if (statusInstance) {
      block.deleteStatusInstance(statusInstance);
    }
  };

  const handleDelete = () => appStore.workspaceModel?.deleteStatusDefinition(statusDefinition);

  const updateStatusDefinitionLabel = (label: string) => {
    const trimmedValue = label.trim();

    if (!trimmedValue) {
      setValue(statusDefinition.label);
    } else {
      statusDefinition.setLabel(trimmedValue);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => setValue(event.target.value);

  const handleInputFocus = (event: React.ChangeEvent<HTMLInputElement>) => event.target.select();

  const handleInputBlur = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    updateStatusDefinitionLabel(value);
  };

  const handleInputKeyUp = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      updateStatusDefinitionLabel(value);
      popoverRef.current?.setState({ isOpen: false });
    }
  };

  const handleChangeStatusType = (statusType: StatusType) => {
    appStore.workspaceModel?.migrateStatusType(statusDefinition, statusType);
  };

  return (
    <Menu>
      <InputGroup
        placeholder="empty"
        className="status-instance-navigation--input"
        onKeyUp={handleInputKeyUp}
        inputRef={inputRef}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        value={value}
        autoFocus
        onChange={handleInputChange}
      />
      <MenuDivider title="Data type" />
      {STATUS_MENU_ITEMS.map(({ icon, text, type }) => (
        <MenuItem
          key={text}
          text={text}
          icon={icon}
          selected={statusDefinition.type === type}
          onClick={() => handleChangeStatusType(type)}
          e2eIdentifiers={["status-menu", text]}
        />
      ))}
      {statusInstance && <CopyLinkMenuItem node={statusInstance} />}
      <MenuDivider />
      <MenuItem
        intent={Intent.WARNING}
        icon="eraser"
        text="Clear Value"
        disabled={!statusInstance}
        onClick={handleClear}
        e2eIdentifiers="clear-value"
      />
      <MenuItemDelete text="Delete Status" onDelete={handleDelete} e2eIdentifiers="delete-status" />
    </Menu>
  );
};

export default observer(StatusInstanceNavigation);
