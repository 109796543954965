import { getHeadingPlaceholder } from "@components/Requirements/RequirementsTable/Cells/HeadingCellRenderer";
import { RequirementBlockType } from "@rollup-api/models";
import { RollupEditorType } from "@rollup-types/editor";
import appStore from "@store/AppStore";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";
import { isHeading } from "@store/Requirements/RequirementsPageStore";
import { EntityType } from "@store/types";

import { IDocumentBlock } from "./DocumentEditor/DocumentBlock.types";

export const reqBlockTypeToRollupEditorType = (reqBlockType: RequirementBlockType): RollupEditorType => {
  switch (reqBlockType) {
    case RequirementBlockType.h1:
      return RollupEditorType.h1;
    case RequirementBlockType.h2:
      return RollupEditorType.h2;
    case RequirementBlockType.h3:
      return RollupEditorType.h3;
    case RequirementBlockType.requirement:
      return RollupEditorType.p;
  }
};

export const reportBlockTypeToReqBlockType = (docBlockType: RollupEditorType): RequirementBlockType => {
  switch (docBlockType) {
    case RollupEditorType.h1:
      return RequirementBlockType.h1;
    case RollupEditorType.h2:
      return RequirementBlockType.h2;
    case RollupEditorType.h3:
      return RequirementBlockType.h3;
    case RollupEditorType.p:
      return RequirementBlockType.requirement;
    default:
      throw new Error(`Unsupported document block type: ${docBlockType}`);
  }
};

export const requirementBlockToDocumentBlock = (requirementBlock: IRequirementBlock): IDocumentBlock => {
  return {
    id: requirementBlock.id,
    type: reqBlockTypeToRollupEditorType(requirementBlock.type),
    label: requirementBlock.label,
    placeholder: isHeading(requirementBlock.type) ? getHeadingPlaceholder(requirementBlock.type) : "Untitled Requirement",
    parentDocument: requirementBlock.parentPage,
    entityType: EntityType.RequirementsBlock,

    updateType: (type: RollupEditorType) => {
      const reqBlockType = reportBlockTypeToReqBlockType(type);
      requirementBlock.setBlockType(reqBlockType);
    },
    updateText: (text: string) => {
      requirementBlock.setLabel(text.replace(/<.*>(.*)<.*>/, "$1"));
    },
    delete: () => {
      appStore.workspaceModel?.deleteRequirementBlock(requirementBlock);
    },
  };
};
