import { useEffect, useState } from "react";
import { EditableText } from "@blueprintjs/core";
import { observer } from "mobx-react";

import type { IStatusDefinition } from "@store/StatusDefinitionStore";
import { IStatusInstance } from "@store/StatusInstanceStore";

import styles from "./StatusTypeTextEditor.module.scss";

type StatusTypeTextEditorProps = {
  statusDefinition: IStatusDefinition;
  statusInstance?: IStatusInstance;
  hidePlaceholder?: boolean;
  disabled?: boolean;
  onAddStatusInstance(statusDefinition: IStatusDefinition, value: string): void;
  onDeleteStatusInstance(statusInstance: IStatusInstance): void;
};

function StatusTypeTextEditor(props: StatusTypeTextEditorProps) {
  const { statusDefinition, statusInstance, hidePlaceholder, disabled, onAddStatusInstance, onDeleteStatusInstance } = props;
  const inputTextDefaultValue = statusInstance?.value || "";

  const [inputText, setInputText] = useState(inputTextDefaultValue);

  useEffect(() => {
    setInputText(inputTextDefaultValue);
  }, [inputTextDefaultValue, statusInstance]);

  if (!statusDefinition) {
    return null;
  }

  if (disabled) {
    return <div className={styles.statusTypeTextEditorDisabled}>{statusInstance?.value}</div>;
  }

  const setValue = (value: string) => {
    if (!statusInstance) {
      if (!value) {
        return; // Don't create new instances for empty values.
      }
      onAddStatusInstance(statusDefinition, value);
    } else {
      if (!value) {
        onDeleteStatusInstance(statusInstance);
      } else {
        statusInstance.setValue(value);
      }
    }
  };

  return (
    <EditableText
      value={inputText}
      onChange={setInputText}
      onConfirm={() => setValue(inputText)}
      placeholder={hidePlaceholder ? "" : "Empty"}
      className={styles.statusTypeTextEditor}
      multiline
      confirmOnEnterKey
    />
  );
}

export type { StatusTypeTextEditorProps };
export default observer(StatusTypeTextEditor);
