import classNames from "classnames";

export type TSearchResultItem = {
  id: string;
  path?: string[];
  label: string;
};

type Props = {
  items: TSearchResultItem[];
  activeId?: string;
  onClick: (id: string) => void;
};

import "./SearchResults.scss";

const SearchResults = (props: Props) => {
  const { items, activeId, onClick } = props;

  if (!items.length) {
    return <div className="search-results search-results-empty">no results</div>;
  }

  const renderItem = (i: TSearchResultItem) => (
    <div
      className={classNames("search-results--item", { ["search-results--item-active"]: activeId === i.id })}
      onMouseUp={() => onClick(i.id)}
    >
      <div className="search-results--name" key={i.id}>
        {i.label}
      </div>
      {i.path && (
        <div className="search-results--parent">
          {i.path.map((blockName, index) => (
            <div key={blockName} style={{ paddingLeft: (index + 1) * 16 }} className="search-results--path">
              • {blockName}
            </div>
          ))}
        </div>
      )}
    </div>
  );

  return <div className="search-results">{items.map(renderItem)}</div>;
};

export default SearchResults;
