export const getFileList = (files: File[]): FileList => {
  const dataTransfer = new DataTransfer();
  files.forEach(file => dataTransfer.items.add(file));
  return dataTransfer.files;
};

export const sanitizeFilename = (input: string) =>
  input
    .replace(/[^a-zA-Z0-9-_.]/g, "_") // Allow letters, numbers, dash, underscore, and dot
    .replace(/_+/g, "_") // Replace multiple underscores with a single one
    .replace(/^-+|-+$/g, "") // Remove leading and trailing dashes
    .replace(/\.+$/, "") // Remove trailing dots
    .substring(0, 255); // Limit to 255 characters (common filename length limit)

export const downloadMarkdown = (content: string, filename = "document.md") => {
  const blob = new Blob([content], { type: "text/markdown" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  URL.revokeObjectURL(link.href);
};
