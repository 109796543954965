import { Menu } from "@blueprintjs/core";
import { observer } from "mobx-react";

import { CellDropdown } from "@components/CellDropdown";
import { MenuItem } from "@components/MenuItem";
import { SuccessCriteriaType } from "@rollup-api/models/requirementBlock";
import { IRequirementBlock } from "@store/Requirements/RequirementBlockStore";

type Props = {
  requirementBlock: IRequirementBlock;
};

const SuccessCriteriaCell = (props: Props) => {
  const { requirementBlock } = props;

  const getMenuContent = () => (
    <Menu>
      <MenuItem
        text={SuccessCriteriaType.Automatic}
        active={requirementBlock.successCriteria === SuccessCriteriaType.Automatic}
        onClick={() => requirementBlock.setSuccessCriteria(SuccessCriteriaType.Automatic)}
        e2eIdentifiers="automatic"
      />
      <MenuItem
        text={SuccessCriteriaType.Manual}
        active={requirementBlock.successCriteria === SuccessCriteriaType.Manual}
        onClick={() => requirementBlock.setSuccessCriteria(SuccessCriteriaType.Manual)}
        e2eIdentifiers="manual"
      />
    </Menu>
  );

  return (
    <CellDropdown
      text={requirementBlock.successCriteria}
      disabled={requirementBlock.locked}
      content={getMenuContent()}
      e2eIdentifiers={requirementBlock.successCriteria}
    />
  );
};

export default observer(SuccessCriteriaCell);
