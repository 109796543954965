export enum RequirementAutoNumberingFormat {
  IntegerIncrement = "IntegerIncrement",
  SectionBased = "SectionBased",
}

export enum RequirementNumberingType {
  Static = "Static",
  Dynamic = "Dynamic",
}

export enum RequirementAutoNumbering {
  Enabled = "Enabled",
  Disabled = "Disabled",
}

export class RequirementsSettingsModel {
  numberingType?: RequirementNumberingType;
  autoNumbering?: RequirementAutoNumbering;
  autoNumberingFormat?: RequirementAutoNumberingFormat;
  numberingSchema?: string;
}
